import Macy from "macy";

window.macyInit = function () {
    if (window.macy) {
        window.macy.recalculate();
    } else {
        window.macy = Macy.init({
            container: "#items-container",
            trueOrder: false,
            waitForImages: false,
            margin: 0,
            columns: 2,
            breakAt: {
                1200: 4,
                940: 2,
                520: 2,
                400: 1,
            },
        });
    }
};
