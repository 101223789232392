function request(url, method, body = {}) {
    const csrf_token = $('meta[name="csrf-token"]').attr("content");
    return new Promise((resolve, reject) => {
        let result = null
        if (method.toLowerCase() === "get") {
            result = fetch(url, {
                method: method,
                headers: {
                    "X-CSRF-Token": csrf_token,
                    Accept: "application/json",
                }
            })

        } else {
            result = fetch(url, {
                method: method,
                headers: {
                    "X-CSRF-Token": csrf_token,
                    Accept: "application/json",
                },
                body: body,
            })
        }
        result.then((res) => {
            return res.json()
        }).then((data) => {
            resolve(data)
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })
}

function get(url, body = {}) {
    return request(url, 'GET', body)
}

function post(url, body = {}) {
    return request(url, 'POST', body)
}

function put(url, body = {}) {
    return request(url, 'PUT', body)

}

function deleteMethod(url, body = {}) {
    return request(url, 'PUT', body)
}

const init_http = {
    get: get,
    post: post,
    put: put,
    deleteMethod: deleteMethod,
}

window.HTTP = init_http