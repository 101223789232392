const all_items_component = function () {
  return {
    onLoadAllMore: function (page, e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      const self = this;
      var params = location.href.split("?");
      if (params.length === 2) {
        params = params[1];
      } else {
        params = "";
      }

      const url = `/items/all_more?page=${page}&${params}`
      HTTP.get(url)
        .then((jsonObj) => {
          const {items, next} = jsonObj.data;
          if (next === null) {
            $(e.target).hide();
          } else {
            $(e.target).attr("x-on:click", `onLoadAllMore(${next}, $event)`);
          }
          items.forEach((it) => {
            $("#items-container").append($(it.wide_node_html));
          });
          bindMessageItemEvent();
        })
        .catch((e) => {
          alert("加载失败");
        });
    },
  };
};

export default all_items_component;
