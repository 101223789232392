import moment from "moment";

export default function MessageViewItem() {
  return {
    onDelete(itemId, target) {
      let flag = confirm("确定删除吗？");
      if (!flag) {
        return;
      }
      const csrf_token = $('meta[name="csrf-token"]').attr("content");
      let path = `/items/${itemId}`;
      HTTP.deleteMethod(path).then((data) => {
        if (data.code === 0) {
          $(target).closest(".message-view__item").remove();
          let itemLength = $(
              ".message-view--today .message-view__items .message-view__item"
          ).length;

          if (itemLength === 0) {
            $(
                ".message-view--today .message-view__items p.placeholder"
            ).show();
          }
        } else {
          alert("操作失败");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("操作失败");
        });
    },
    onPin(itemId, target) {
      const csrf_token = $('meta[name="csrf-token"]').attr("content");
      let path = `/items/${itemId}/pin`;
      HTTP.post(path)
        .then((data) => {
          if (data.code === 0) {
            $(".message-view--pin .message-view__items").prepend(
              $(target).closest(".message-view__item")
            );
            $(target)
              .closest(".message-view__item")
              .find(".action.action--pin")
              .addClass("is-hidden");
            $(target)
              .closest(".message-view__item")
              .find(".action.action--cancel-pin")
              .removeClass("is-hidden");
          } else {
            alert("操作失败");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("操作失败");
        });
    },
    onPreviewImg(image) {
      $('.modal--thumbnail').find('img').attr('src', image);
      openModal($('.modal--thumbnail')[0])
    },
    onCancelPin(itemId, target) {
      const csrf_token = $('meta[name="csrf-token"]').attr("content");
      let path = `/items/${itemId}/cancel_pin`;
      HTTP.post(path)
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 0) {
            $(target)
              .closest(".message-view__item")
              .find(".action.action--pin")
              .removeClass("is-hidden");
            $(target)
              .closest(".message-view__item")
              .find(".action.action--cancel-pin")
              .addClass("is-hidden");
            let itemDateText = new Date(
              $(target)
                .closest(".message-view__item__header")
                .find("span")
                .text()
            );

            let isToday = moment(itemDateText).isSame(moment(), "day");

            if (isToday) {
              $(".message-view--today .message-view__items").prepend(
                $(target).closest(".message-view__item")
              );
            } else {
              $(".message-view--last .message-view__items").prepend(
                $(target).closest(".message-view__item")
              );
            }
          } else {
            alert("操作失败");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("操作失败");
        });
    },
  };
}
