import Alpine from "alpinejs";
import messageView from "./components/message_view";
import messageInputView from "./components/message_input_view";
import MessageViewItem from "./components/message_view_item";
import all_items_component from "./components/all_items_component";
import sidebar_component from "./components/sidebar_component";
import check_component from "./components/check_component";

window.Alpine = Alpine;
queueMicrotask(() => {
    Alpine.start();
});

window.message_view = messageView;

window.message_input_view = messageInputView;

window.MessageViewItem = MessageViewItem;

window.AllItemsComponent = all_items_component;

window.sidebar_component = sidebar_component;

window.check_component = check_component;

// import links_component from "./components/links_component";
// window.links_component = links_component;
