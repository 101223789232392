let message_input_view = function () {
  return {
      content: "",
      imgUrl: null,
      isPrivate: false,
      loading: false,
      onInit: function () {
          this.content = $("textarea").val().trim();
      },
      onKeydown: function (e) {
          if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13)) {
              this.onSend();
              return
          }

          //  当内容中有链接的时候
          if (e.keyCode === 13) {
              const content = this.content;
              if (content.includes(" - ")) {
                  return
              }
              const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
              const self = this
              if (reg.test(content)) {
                  const matchUrl = content.match(reg)[0];
                  // 调用请求接口，获取链接标题
                  const csrf_token = $('meta[name="csrf-token"]').attr("content");
                  const url = `/links/get_title?uri=${matchUrl}`;
                  self.loading = true
                  HTTP.get(url)
                      .then((res) => res.json())
                      .then((result) => {
                          let {data} = result;
                          let {title} = data;
                          if (title !== "") {
                              self.content = content.replace(matchUrl, `${matchUrl} - ${title}`);
                          }
                      })
                      .catch((err) => {
                          console.log(err);
                      }).finally(() => {
                      self.loading = false
                  });

              }
          }
      },
      onSend: function () {
          const csrf_token = $('meta[name="csrf-token"]').attr("content");
          if (this.content.trim() === "") {
              alert("内容不能为空");
              return;
          }
          let formData = new FormData();
          formData.append("log[content]", this.content);
          formData.append("log[image]", this.imgUrl);
          formData.append("is_private", this.isPrivate === true ? 1 : 0);
          formData.append("item_type", 1001);
          HTTP.post("/items", formData)
              .then((data) => {
                  if (data["code"] === 0) {
                      document.location.reload();
                      return;
                  } else {
                      alert(data["message"]);
                  }
                  return;
                  let item = data["data"];
                  let {item_html} = item;
          let newItem = $(item_html);
          $(".message-view--today .message-view__items p.placeholder").hide();
          let itemLength = $(
            ".message-view--today .message-view__items .message-view__item"
          );

          $(".message-view--today .message-view__items").prepend(newItem);
          this.content = "";

          bindEvents();
        })
        .catch((err) => {
          console.log(err);
          alert("操作失败");
        });
    },
    onSelectedFile: function (e) {
      e.stopPropagation();
      const files = e.target.files;
      this.imgUrl = null;
      if (FileReader && files && files.length > 0) {
        let fileObj = files[0];
        const csrf_token = $('meta[name="csrf-token"]').attr("content");
        let formData = new FormData();
        formData.append("file", fileObj);
          HTTP.post("/upload", formData)
          .then(({ data }) => {
            let { url } = data;
            $(".img-container>img").attr("src", url);
            this.imgUrl = url;
          })
          .catch((err) => {
            console.log(err);
            this.imgUrl = null;
            alert("操作失败");
          });
      }
    },
    onUploadImg: function (e) {
      e.stopPropagation();
      $("#inputFile").trigger("click");
    },
    onRemoveImg: function () {
      this.imgFile = null;
      $("#inputFile").val("");
    },
  };
};

export default message_input_view;
