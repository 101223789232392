function bindEvents() {
    $(".message-view__item").on("mouseenter", function (e) {
        $(e.target).find(".hide-hover").hide();
        $(e.target).find(".show-hover").show();
    });

    $(".message-view__item").on("mouseleave", function (e) {
        $(e.target).find(".hide-hover").show();
        $(e.target).find(".show-hover").hide();
    });

    document.addEventListener("DOMContentLoaded", () => {
        // Functions to open and close a modal
        function openModal($el) {
            $el.classList.add("is-active");
        }
        window.openModal = openModal;

        function closeModal($el) {
            $el.classList.remove("is-active");
        }

        function closeAllModals() {
            (document.querySelectorAll(".modal") || []).forEach(($modal) => {
                closeModal($modal);
            });
        }

        // Add a click event on various child elements to close the parent modal
        (
            document.querySelectorAll(
                ".modal-background, .modal-close, .modal-card-head .delete"
            ) || []
        ).forEach(($close) => {
            const $target = $close.closest(".modal");

            $close.addEventListener("click", () => {
                closeModal($target);
            });
        });

        // Add a keyboard event to close all modals
        document.addEventListener("keydown", (event) => {
            const e = event || window.event;

            if (e.keyCode === 27) {
                // Escape key
                closeAllModals();
            }
        });

        // close notification
        (document.querySelectorAll(".message .delete") || []).forEach(($delete) => {
            const $notification = $delete.parentNode;

            $delete.addEventListener("click", () => {
                $notification.parentNode.removeChild($notification);
            });
        });

        // toggle menu
        const $navbarBurgers = Array.prototype.slice.call(
            document.querySelectorAll(".navbar-burger"),
            0
        );

        // Add a click event on each of them
        $navbarBurgers.forEach((el) => {
            el.addEventListener("click", () => {
                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle("is-active");
                $target.classList.toggle("is-active");
            });
        });
    });

    window.bindMessageItemEvent = function () {
        $(".message-view__item").on("mouseenter", function (e) {
            $(e.target).find(".hide-hover").hide();
            $(e.target).find(".show-hover").show();
        });

        $(".message-view__item").on("mouseleave", function (e) {
            $(e.target).find(".hide-hover").show();
            $(e.target).find(".show-hover").hide();
        });

        $(".ajax-handle").on("click", function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            const itemNode = $(this).closest(".message-view__item");
            const opt = $(this).data("opt");
            const isDelete = $(this).data("type") === "delete";
            const isPin = $(this).data("type") === "pin";
            const csrf_token = $('meta[name="csrf-token"]').attr("content");
            const msg = $(this).data("message");
            const link = $(this).data("link");
            const itemId = $(this).data("item-id");
            const formData = new FormData();
            formData.append("_csrf_token", csrf_token);
            var r = true;
            if (msg && msg !== "") {
                r = confirm(msg);
            }
            if (r) {
                if (window.isProcessing) {
                    return;
                }
                window.isProcessing = true;
                HTTP.post(link, formData)
                    .then((result) => {
                        if (opt === "notReload") {
                            if (isDelete) {
                                $(itemNode).remove();
                            }
                            if (isPin) {
                                $(".message-view--pin .message-view__items").prepend(
                                    $(itemNode)
                                );
                            }

                            // 判断三个栏目是否为空，为空则刷新
                            const todayLength = $(
                                ".message-view--today .message-view__items .message-view__item"
                            ).length;
                            const lastLength = $(
                                ".message-view--last .message-view__items .message-view__item"
                            ).length;
                            const pinLength = $(
                                ".message-view--pin .message-view__items .message-view__item"
                            ).length;

                            // if (todayLength === 0 || lastLength === 0 || pinLength === 0) {
                            //   location.reload();
                            // }
                            return;
                        }
                        window.isProcessing = true;
                        location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert("操作失败");
                    })
                    .finally(() => {
                        window.isProcessing = false;
                    });
            }
        });
        // macyInit();
    };

    window.constants = {
        PAGE_COUNT: 20,
    };
}

export default bindEvents;