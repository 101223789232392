window.message_view_component = function () {
  return {
    last_page: 2,
    today_page: 1,
    pin_page: 1,
    onLoadMore: function (type, e) {
      const params = new URLSearchParams();
      const item_container = "";
      let url = ""
      const self = this;

      if (type === "last") {
        params.set("isLast", "true");
        items_container = $(
          ".message-view.message-view--last .message-view__items"
        );
        url = `/items/last/more?page=${this.last_page}`
      } else if (type === "today") {
        params.set("isToday", "true");
        items_container = $(
            ".message-view.message-view--today .message-view__items"
        );
        url = `/items/last/more?page=${this.today_page}`
      } else if (type === "pin") {
        params.set("isPin", "true");
        items_container = $(
            ".message-view.message-view--pin .message-view__items"
        );
        url = `/items/last/more?page=${this.pin_page}`
      }

      debugger

      HTTP.get(url).then((jsonObj) => {
        const items = jsonObj.data;
        if (items.length < window.constants.PAGE_COUNT) {
          $(e.target).hide();
          return;
        }
        items.forEach((it) => {
          items_container.append(
              $(`<div class='mb-4'>${it.node_html}</div>`)
          );
        });
          bindMessageItemEvent();
          if (type === "last") {
            self.last_page += 1;
          } else if (type === "today") {
            self.today_page += 1;
          } else if (type === "pin") {
            self.pin_page += 1;
          }
        })
        .catch((e) => {
          alert("加载失败");
        });
    },
  };
};
