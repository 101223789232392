function check_component() {
    return {
        checkContent: "",
        currentCheck: null,
        currentCheckTitle: "",
        currentCheckColor: "world",
        currentCheckGroupId: 0,
        currentCheckGroupContent: "",
        onShowCheckModal: function (check) {
            this.currentCheck = check;
            const modal = document.querySelector("#check-modal");
            modal.classList.add("is-active");
        },
        handleChangeCategory: function (event) {
            //  得到当前选项
            console.log(this.currentCheckGroupId)
            //更新当前 log 的 category
            //  得到当前标题
            let formData = new FormData();
            if (this.currentCheckGroupId !== 0) {
                formData.append("check_category[id]", this.currentCheckGroupId);
            } else if (this.currentCheckTitle !== "") {
                formData.append("check_category[title]", this.currentCheckTitle);
            }
            formData.append('log_id', this.currentCheck.id)
            const url = '/check/update'
            HTTP.post(url, formData).then((data) => {
                location.reload();
            }).catch((err) => {
                console.log(err);
                alert("操作失败");
            });
        },
        handleDeleteChecks: function (check) {
            if (confirm('所有打卡记录都将被删除，是否确认？')) {
                const url = `/check/${check['id']}/delete?content=${check.content}`
                init_http.deleteMethod(url).then((result) => {
                    location.reload();
                }).then((res) => {
                    location.reload();
                }).catch((err) => {
                    alert('操作失败')
                })
            }
        }
    };
}

export default check_component;
