function sidebar_component() {
  return {
    isPrivate: false,
    isArticle: false,
    isUnPrivate: false,
    isPin: false,
    isToday: false,
    isLast: false,
    containLink: false,
    currentTags: [],
    isAll: false,
    date: null,
    updateCheckBox: function () {
      if (this.isArticle === true) {
        document.querySelector("#checkbox-article").checked = true;
      }
      if (this.isPrivate === true) {
        document.querySelector("#checkbox-private").checked = true;
      }
      if (this.isUnPrivate === true) {
        document.querySelector("#checkbox-un-private").checked = true;
      }
      if (this.isPin === true) {
        document.querySelector("#checkbox-pin").checked = true;
      }
      if (this.containLink === true) {
        document.querySelector("#checkbox-contain-link").checked = true;
      }
      if (this.isToday === true) {
        document.querySelector("#checkbox-today").checked = true;
      }
      if (this.isLast === true) {
        document.querySelector("#checkbox-last").checked = true;
      }
      if (this.isAll === true) {
        document.querySelector("#all-items").checked = true;
      }

      if (this.currentTags.length > 0) {
        document.querySelector("#all-items").checked = false;
      }
    },
    updateStatus: function (status) {
      if (status === "isPrivate") {
        this.isPrivate = !this.isPrivate;
      } else if (status === "isUnPrivate") {
        this.isUnPrivate = !this.isUnPrivate;
      } else if (status === "isArticle") {
        this.isArticle = !this.isArticle;
      } else if (status === "isPin") {
        this.isPin = !this.isPin;
      } else if (status === "containLink") {
        this.containLink = !this.containLink;
      } else if (status === "isToday") {
        this.isToday = !this.isToday;
      } else if (status === "isLast") {
        this.isLast = !this.isLast;
      } else if (status === "isAll") {
        this.isAll = !this.isAll;
      }

      // 用户选择的不是 isAll
      if (status !== "isAll") {
        if (
          this.isPrivate ||
          this.isUnPrivate ||
          this.isPin ||
          this.containLink ||
          this.isToday ||
          this.isLast ||
          this.isArticle ||
          this.currentTags.length > 0
        ) {
          this.isAll = false;
          document.querySelector("#all-items").checked = false;
        }

        if (
          !this.isPrivate &&
          !this.isUnPrivate &&
          !this.isPin &&
          !this.containLink &&
          !this.isToday &&
          !this.isLast &&
          !this.isArticle &&
          this.currentTags.length === 0
        ) {
          this.isAll = true;
          document.querySelector("#all-items").checked = true;
          return;
        }
      }

      if (this.isAll) {
        this.isPrivate = false;
        this.isArticle = false;
        this.isUnPrivate = false;
        this.isPin = false;
        this.isToday = false;
        this.isLast = false;
        this.containLink = false;

        // 所有复选框都为uncheck
        const checkboxes = document.querySelectorAll("[id^=checkbox]");
        [...checkboxes].map((el) => {
          el.checked = false;
        });
      }
    },

    onLoadData: function (status) {
      this.updateStatus(status);
      console.log("status: " + status);
      const params = new URLSearchParams();
      // 根据参数构建URL
      if (this.isPin) {
        params.set("isPin", "true");
      }

      if (this.containLink) {
        params.set("containLink", "true");
      }

      if (this.isToday) {
        params.set("isToday", "true");
      }

      if (this.isLast) {
        params.set("isLast", "true");
      }

      if (this.isArticle) {
        params.set("isArticle", "true");
      }

      if (this.isPrivate) {
        params.set("isPrivate", "true");
      }

      if (this.isUnPrivate) {
        params.set("isUnPrivate", "true");
      }

      if (this.isAll) {
        params.set("isAll", true);
      } else {
        params.set("tagIds", this.currentTags.join(","));
      }

      if (this.date) {
        let dateStr = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + "-" + this.date.getDate();
        params.set("date", dateStr);
      }

      // 跳转页面
      const url = new URL(location.href);
      url.search = params.toString();
      console.log(params.toString());
      console.log(url.href);
      location.href = url.href;
    },
    onSideBarInit: function () {
      const url = new URL(location.href);
      const params = new URLSearchParams(url.search);
      if (params.get("isPrivate") === "true") {
        this.isPrivate = true;
      }

      if (params.get("isUnPrivate") === "true") {
        this.isUnPrivate = true;
      }

      if (params.get("isArticle") === "true") {
        this.isArticle = true;
      }

      if (params.get("isPin") === "true") {
        this.isPin = true;
      }

      if (params.get("containLink") === "true") {
        this.containLink = true;
      }

      if (params.get("isToday") === "true") {
        this.isToday = true;
      }

      if (params.get("isLast") === "true") {
        this.isLast = true;
      }

      if (params.get("isAll") === "true") {
        this.isAll = true;
      }

      if (params.toString() === "") {
        this.isAll = true;
      }

      if (params.get("tagIds")) {
        this.currentTags = params.get("tagIds").split(",");
      }

      this.updateCheckBox();
    },
    onClickTag(tagId) {
      if (this.currentTags.includes(tagId)) {
        console.log(this.currentTags);
        this.currentTags = this.currentTags.filter(function (v) {
          return v != tagId;
        });
        console.log(this.currentTags);
        this.onLoadData("");
      } else {
        this.currentTags.push(tagId);
        this.onLoadData("");
      }
    },
    onClickCalender(idx, count) {
      if (count === 0) {
        return
      }
      this.isAll = true
      let date = new Date()
      //设置当前月份的第几天
      date.setDate(idx + 1)
      this.date = date
      this.onLoadData("");
      console.log()
    }
  };
}

export default sidebar_component;
