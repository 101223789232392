// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "whatwg-fetch";
import "moment";

import Cash from "cash-dom";
import feather from "feather-icons";
import bindEvent from "./init_events";
import "./init_alpine"
import "./init_macy"
import "./init_http"

window.$ = Cash;
feather.replace();
bindEvent()
